#auth-form-signin{
    max-width: 576px;
}
#auth-form-signup{
    max-width: 576px;
}
#auth-form-reset{
    max-width: 576px;
}


.logreg-forms .facebook-btn{  background-color:#3C589C; }

.logreg-forms .google-btn{ background-color: #DF4B3B; }







 