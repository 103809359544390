$primary: #5BC0E7;
$danger:   #D93C23; // #FF3B12;
$warning: #FFBB12;
$info: #B9E7F9;






@import 'custom.scss';
@import 'dropzone.scss';
@import 'custom.css';
@import '~bootstrap/scss/bootstrap.scss';


