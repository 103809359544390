.opacity-3 {
    opacity:0.6!important;
  }

  .opacity-0 {
    opacity:0!important;
  }

  .opacity-5 {
    opacity:1!important;
  }
  .button.btn-favorite{
    background: url(/img/heart.svg) no-repeat;
    cursor:pointer;
    border: none;
    min-width: 44px;
    min-height: 44px;
    width: 44px;
    height: 44px;    
}




.carousel-item {

  &.active {
    z-index: 1;
    img {
      transform-origin: center;
      transition: transform 5s, filter 3s ease-in-out;
      filter: brightness(100%); 
      border-radius: 0.3rem !important;
      &:hover {
        filter: brightness(95%);
        transform: scale(1.1);
        border-radius: 0.3rem !important;
      }
    }
  
  }

  height: 240px;


  img {
    width: 100%;
    border-radius: 0.3rem !important;
    height: 240px;
  }


}

html {
  background-color:white;
}

body {
  background-color: white;
}

.ais-GeoSearch-map {
  height: 500px; /* You can adapt the height */
}

#geo-search {
  height: 100%; /* You can adapt the height */
  border-radius: 15px;
  -webkit-transform: translate3d(0px, 0px, 0px);
  -webkit-mask-image: -webkit-radial-gradient(white, black);  
}

.ul-price {
  width: 360px;
  .form-control {
    width: 80px;
  }
}

.price-label {
  min-width: 160px;
}

#refinement, #garages, #baths {
  min-width: 80px;
}

.bgimage {
  width:100%;
  height:500px;
	display: flex;
	align-items: center;
	justify-content: center;
	&::before {
		content: "";
    background: url('/img/househero.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    background-attachment: fixed;
		opacity: 0.75;
	}
}





  .house {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-0 * 162px);    
  }

  .key {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-1 * 162px);  
  }

  .building {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-2 * 162px);    
  }

 .eco {
  background-image: url('/img/realicons.png');
  width: 178px;
  height: 162px;
  background-position: calc(-0 * 178px) calc(-2 * 162px);    
}

  .building2 {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-2 * 162px);    
  }

  .charts {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-2 * 162px);    
  }

  .calendar {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-2 * 162px);    
  }

 .floorplan {
  background-image: url('/img/realicons.png');
  width: 178px;
  height: 162px;
  background-position: calc(-0 * 178px) calc(-2 * 162px);    
}

  .key2 {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-2 * 162px);    
  }

  .house2 {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-0 * 178px) calc(-2 * 162px);    
  }

  .forsale {
    background-image: url('/img/realicons.png');
    width: 178px;
    height: 162px;
    background-position: calc(-1 * 178px) calc(-3 * 162px);    
  }

 .location {
  background-image: url('/img/realicons.png');
  width: 178px;
  height: 162px;
  background-position: calc(-0 * 178px) calc(-2 * 162px);    
}

#marketing {
  img {
    height: 240px;
  }
}

.title-class {
position: relative;
color: #252839;
-webkit-text-stroke: 0.3vw #383d52;

}

.title-class::before {
  content:attr(data-text);
  position: absolute;
top:0;
left:0;
width:0;
height:100%;
color: #01fe87;
-webkit-text-stroke:0vw #383d52;
border-right: 2px solid #01fe87;
overflow: hidden;
animation: animate 6s linear infinite;
}

@keyframes animate {
  0%,10%,100% {
    width: 0;
  }
  70%,90% {
    width: 100%;
  }  
}

.icon-circle {
  background-image:url('data:image/svg+xml;charset=utf-8,%3Csvg viewBox=%220 0 10 10%22 xmlns=%22http://www.w3.org/2000/svg%22%3E%3Ccircle fill=%22%23fd9827%22 cx=%225%22 cy=%225%22 r=%225%22 /%3E%3C/svg%3E');
  height:32px;
  width:32px;
}




@import 'icons';
.icon-at {
  background-image: get-icon-at(rgb(187, 68, 68));
  height: 48px;
  width: 48px;
}
.icon-cog {
  background-image: get-icon-cog(#259d78);
  height: 48px;
  width: 48px;
}
.icon-anchor {
  background-image: get-icon-anchor(#387bd0);
  height: 48px;
  width: 48px;
}

.icon-house {
  background-image: get-icon-house(#387bd0);
  background-repeat: no-repeat;
  height: 80px;
  width: 100px;
}


.custom-element {
  color: color-contrast(#000); // returns `color: #fff`
}


#photosSection  {


}

$bpxxl: 1400px;
$bpxl: 1200px;
$bplg: 992px;
$bpmd: 768px;
$bpsm: 576px;

$breakimg: 70%;

#photosSection  {
  .container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;  
    .images {
      transition: transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      &:hover { 
          transform: scale(1.03);   
      }
    }

 
    
  }
  max-width: 1000px !important;
  width: 1000px !important;
  .img-wrapper {
    height: 500px; 
      img {
        position:relative;
       object-fit: cover;
       width: 100%;  
      }    
  }
  .img-wrapper2 {
    height: 246px; 
      img {
        position:relative;
        object-fit: cover;
        height: 100%;  
      }    
  }

}

.small-text {
  font-size: 10px; 
  line-height: 1.6;
  letter-spacing: -0.1px
}

// virtual tour css

#viewer {
  width: 100%;
  height: 600px;
}