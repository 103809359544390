.psv-settings-item-label {
  flex: 1;
  font-weight: bold; }

.psv-settings-item-value {
  flex: none; }

.psv-settings-item-icon {
  flex: none;
  height: 1em;
  width: 1em; }
  .psv-settings-item-icon svg {
    width: 1em;
    height: 1em; }
  .psv-settings-item-icon:first-child {
    margin-right: 1em; }
  .psv-settings-item-icon:last-child {
    margin-left: 1em; }

.psv-settings-item--header {
  border-bottom: 1px solid currentColor;
  margin-bottom: 1em; }
  .psv-settings-item--header svg {
    transform: scaleX(-1); }
